<template>
  <b-list-group style="border-radius: 0">
    <b-list-group-item
      class="d-flex justify-content-between align-items-center"
      style="border-radius: 0"
      @click="showChildren()"
      :variant="variant"
    >
      {{ index }}. {{ name }}
      <download-excel
        class="btn btn-primary btn-sm pull-right"
        :fields="header"
        :fetch="fetchData"
        :before-generate="startDownload"
        :before-finish="finishDownload"
        :name="
          name +
            ' ' +
            today +
            ' (' +
            getStartDate +
            ' - ' +
            getEndDate +
            ').xls'
        "
        :worksheet="getStartDate + ' - ' + getEndDate"
        v-show="children.length == 0"
      >
        <b-spinner small type="grow" v-show="download.loading"></b-spinner>
        <span v-show="download.loading" class="ml-1">Уншиж байна</span>
        <i class="fa fa-download" v-show="!download.loading"></i>
        <span v-show="!download.loading" class="ml-1">Excel татах</span>
      </download-excel>
      <i
        class="fa pull-right"
        :class="{
          ' fa-chevron-up': show_children,
          'fa-chevron-down': !show_children,
        }"
        v-show="children.length > 0"
      >
      </i>
    </b-list-group-item>
    <ReportItem
      :variant="variant"
      style="border-radius: 0"
      v-show="show_children"
      v-for="(_report, _index) in children"
      :key="_index"
      :dateRange="dateRange"
      :name="_report.name"
      :slug="_report.slug"
      :header="_report.config.header"
      :index="index + '.' + new String(_index + 1)"
      :children="_report.hasOwnProperty('child') ? _report.child : []"
    >
    </ReportItem>
  </b-list-group>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "ReportItem",
  components: { axios },
  props: {
    dateRange: {
      type: [Array, Object],
      default: () => [],
    },
    name: {
      type: String,
      default: "",
    },
    slug: {
      type: String,
      default: "",
    },
    header: {
      type: [Array, Object],
      default: () => [],
    },
    selectedOps: {
      type: [Array, Object],
      default: () => [],
    },
    index: {
      type: String,
      default: "",
    },
    children: {
      type: [Array, Object],
      default: () => [],
    },
    variant: {
      type: String,
      default: "",
    },
  },
  data: function() {
    return {
      download: {
        loading: false,
      },
      today: moment().format("YYYY-MM-DD"),
      show_children: false,
    };
  },
  computed: {
    getStartDate() {
      return moment(this.dateRange.startDate).format("YYYY-MM-DD");
    },
    getEndDate() {
      return moment(this.dateRange.endDate).format("YYYY-MM-DD");
    },
    getEndDateTo() {
      return moment(this.dateRange.endDate)
        .add(1, "days")
        .format("YYYY-MM-DD");
    },
  },
  methods: {
    showChildren: function() {
      if (this.children.length > 0) {
        this.$data.show_children = !this.$data.show_children;
        this.variant = this.$data.show_children ? "info" : "";
      }
    },
    async fetchData() {
      if (this.download.loading) {
        var ids = [];

        if (this.$store.state.user != null) {
          // if(this.$store.state.user.role.code != 'OPERATOR' && this.$store.state.user.role.code != 'CONTRACT') {
          for (var i in this.selectedOps) {
            ids.push(this.selectedOps[i].id);
          }
          // } else {
          // ids.push(this.$store.state.user.id);
          // }
        }
        //console.log(ids);

        this.download.loading = true;
        const response = await axios.get(
          this.$config.API_URL + "ReportWebService/get_list_audit",
          {
            params: {
              start_at: this.getStartDate,
              end_at: this.getEndDateTo,
              type: this.slug,
              ids: JSON.stringify(ids),
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        );
        if (response.data.status == 500) {
          showToast("Алдаа", "Алдаа гарсан байна. Дахин оролдоно уу", "danger");
          this.$data.download.loading = false;
          return [];
        }
        let _result = [];
        for (let i in response.data.data) {
          let _item = response.data.data[i];
          _item.index = parseInt(i) + 1;
          _result.push(_item);
        }
        return _result;
      }
      return [];
    },
    startDownload() {
      this.download.loading = true;
    },
    finishDownload() {
      this.download.loading = false;
    },
  },
};
</script>
